import React from "react";
import { Link } from "gatsby";
import parse from "html-react-parser";

const RelatedPost = ({ dataCategory,cate }) => {

  const posts = dataCategory?.edges;

  const generateLinkImage = (url) => {
    if(url) {
      return url.replace(`${process.env.GATSBY_SITE_SOURCE_IMAGE}`, `${process.env.GATSBY_SITE_CDN_IMAGE}`)
    } else {
      return `${process.env.GATSBY_SITE_URL}/default-image.png`
    }
  }

  return (
    <div className="category-page__more__section">
      <div className="container more__section">
        <h2 className="category-page__more__section__title">
          <span>Bài viết {cate} - Du lịch liên quan</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="19"><path d="M13.235 10.185c-5.434.855-5.955 1.548-6.598 8.772-.643-7.224-1.164-7.917-6.597-8.772 5.433-.855 5.954-1.548 6.597-8.771.643 7.223 1.164 7.916 6.598 8.771zm1.725-6.289c-2.386.376-2.615.68-2.898 3.853-.282-3.173-.511-3.478-2.898-3.853 2.387-.376 2.616-.68 2.898-3.853.283 3.173.512 3.477 2.898 3.853z" fill="#424242"></path></svg>
        </h2>
        <div className="category-page__more__post__container">
          {
            posts?.length > 0 && posts.map((post, index) => (
              <div key={index} className="category-page__more__post__item">
                <div className="category-page__more__post__item__image">
                  <Link to={post.node.uri} itemProp="url">
                    <img 
                      src={generateLinkImage(post?.node?.seo?.opengraphImage?.mediaItemUrl || `${process.env.GATSBY_SITE_URL}/default-image.png`)} 
                      alt={post?.node?.title || `${process.env.GATSBY_SITE_NAME}`} 
                      placeholder="blurred" 
                      loading="lazy" 
                    />
                  </Link>
                </div>
                <div className="category-page__more__post__item__content">
                  <Link to={post.node.uri} itemProp="url">
                    <h3 className="category-page__more__post__item__content__title" itemProp="headline">{parse(post?.node?.title)}</h3>
                  </Link>
                  <Link to={`/${post?.node?.categories?.nodes[0]?.slug}/ `} itemProp="url" className="category-page__more__post__item__link">
                    <p className="style-tag">{parse(post?.node?.categories?.nodes[0]?.name || ``)}</p>
                  </Link>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default RelatedPost