import { useEffect } from "react";
const ScriptComponent = () => {

  useEffect(() => {
    setTimeout(() => {
      const script = document.createElement('script');
      script.src = `${process.env.GATSBY_SITE_URL}/script.js`;
      script.async = true;
      document.body.appendChild(script);
    }
    , 100);
  }, []);
  return null;
};
export default ScriptComponent